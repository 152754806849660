<template>
  <div class="text-left">
    <BaseHeader :title="'Clients'"></BaseHeader>

    <BaseLoading
      style="min-height: 400px; height: 90vh"
      v-if="loading"
    ></BaseLoading>

    <section v-else>
      <div class="card mt-2 border-0 bg-white shadow">
        <div class="card-body text-right d-flex" style>
          <v-text-field
            v-model="$store.state.search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="my-auto"
          ></v-text-field>
          <v-spacer></v-spacer>
        </div>
        <!-- datatable -->
        <v-data-table
          :mobile-breakpoint="0"
          :headers="headers"
          :items="clients"
          :search="$store.state.search"
          :hide-default-footer="true"
          :disable-pagination="true"
        >
          <template v-slot:[`item.deleted`]="{ item }">
            <v-chip
              v-if="item.status == '1'"
              color="primary"
              small
              outlined
              >Active</v-chip
            >
            <v-chip v-else color="error" small>Inactive</v-chip>
          </template>
          <template v-slot:[`item.names`]="{ item }">
            <router-link
              :to="{ name: 'ClientProfile', params: { id: item.id || '' } }"
              >{{ item.names }}</router-link
            >
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              elevation="0"
              :to="{ name: 'ClientProfile', params: { id: item.id } }"
              fab
              x-small
            >
              <v-icon color="primary">mdi-eye</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <!-- /datatable -->
        <!-- pagination -->
        <div class="card-footer" v-if="clients.length > 0">
          <p class="text-left">
            From {{ filters.from || "" }} to {{ filters.to || "" }} of
            {{ filters.total || "" }} Clients
          </p>
          <ul class="pagination pagination-flat align-self-center px-0">
            <li class="page-item" v-if="filters.prev_page_url">
              <a class="page-link" @click="navigate(filters.prev_page_url)"
                >← &nbsp; Prev</a
              >
            </li>
            <li class="page-item active">
              <a class="page-link">On Page {{ filters.current_page || "" }}</a>
            </li>
            <li class="page-item" v-if="filters.next_page_url">
              <a class="page-link" @click="navigate(filters.next_page_url)"
                >Next &nbsp; →</a
              >
            </li>
          </ul>
        </div>
        <!-- ./pagination -->
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "Clients",
  computed: {
    ...mapState("clientusers", ["loading", "clients", "headers", "filters"]),
  },
  data() {
    return {
      countries: [],
    };
  },

  methods: {
    ...mapActions("clientusers", ["_getClients", "_navigate"]),
    navigate(route) {
      this.loading = true;
      this._navigate(route)
        .catch((err) => console.log(err))
        .finally(() => (this.loading = false));
    },
  },
  mounted() {
    this._getClients()
      .then(() => {
        console.log("done");
      })
      .catch((err) => console.log(err));
  },
};
</script>